import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import MediaQuery from 'react-responsive'
import { Table, Pagination, Input, Checkbox, Icon } from 'semantic-ui-react'
import { DebounceInput } from 'react-debounce-input'

/**
 * Takes an object and show its content in a table. All the pagination,
 * order, row/items selection and search is handled by the backend (with API requests).
 *
 * @class TableBackEnd
 * @extends {PureComponent}
 */
export class TableBackEnd extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    apiRequestOptions: PropTypes.object,
    header: PropTypes.object,
    dataExtraction: PropTypes.func,
    handleApiRequestError: PropTypes.func,
    showIdCol: PropTypes.bool,
    hasLoader: PropTypes.bool,
    hasPagination: PropTypes.bool,
    pageUrlVar: PropTypes.string,
    pageSizeUrlVar: PropTypes.string,
    defaultPage: PropTypes.number,
    defaultPageSize: PropTypes.number,
    totalPagesExtraction: PropTypes.func,
    hasSearch: PropTypes.bool,
    searchUrlVar: PropTypes.array,
    defaultSearch: PropTypes.string,
    hasSort: PropTypes.bool,
    sortColUrlVar: PropTypes.string,
    sortDirUrlVar: PropTypes.string,
    defaultSortCol: PropTypes.string,
    defaultSortDir: PropTypes.string,
    hasRowSelection: PropTypes.bool,
    handleRowSelection: PropTypes.func,
    hasItemsSelection: PropTypes.bool,
    handleItemSelection: PropTypes.func,
    handleAllItemsSelection: PropTypes.func,
  }
  static defaultProps = {
    apiRequestOptions: {},
    header: {},
    dataExtraction: data => data,
    handleApiRequestError: () => null,
    showIdCol: false,
    hasLoader: true,
    hasPagination: true,
    pageUrlVar: 'page',
    pageSizeUrlVar: 'limit',
    defaultPage: 1,
    defaultPageSize: 25,
    totalPagesExtraction: data => 1,
    hasSearch: false,
    searchUrlVar: ['search'],
    defaultSearch: '',
    hasSort: true,
    sortColUrlVar: 'sort',
    sortDirUrlVar: 'direction',
    defaultSortCol: '',
    defaultSortDir: 'desc',
    hasRowSelection: false,
    handleRowSelection: () => null,
    hasItemsSelection: false,
    handleItemSelection: () => null,
    handleAllItemsSelection: () => null,
  }

  state = {
    status: 'loading',
    dataRaw: null,
    activePage: this.props.defaultPage,
    pageSize: this.props.defaultPageSize,
    totalPages: 1,
    search: this.props.defaultSearch,
    sortColumn: this.props.defaultSortCol,
    sortDirection: this.props.defaultSortDir,
    selectedRowId: null,
    selectedItemsList: {},
    allItemsPageSelected: {},
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.url !== this.props.url ||
      prevState.activePage !== this.state.activePage ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.sortColumn !== this.state.sortColumn ||
      prevState.sortDirection !== this.state.sortDirection ||
      prevState.search !== this.state.search
    )
      this.getData()
  }

  // When the user clicks on a page button.
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage })
  }

  // When the user clicks on a column header (and the table and
  // clicked column have sorting enabled).
  handleSort = column => {
    this.setState(prevState => ({
      sortColumn: column,
      sortDirection:
        prevState.sortColumn === column
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc',
      selectedItemsList: {},
      allItemsPageSelected: {},
    }))
  }

  // When the user write something in the search input.
  handleSearch = e => {
    this.setState({
      search: e.target.value,
      activePage: 1,
      selectedItemsList: {},
      allItemsPageSelected: {},
    })
  }

  // When the user clicks on a row (and table has row selection enabled)
  handleRowSelection = (e, rowData) => {
    if (e.target.nodeName === 'TD' && e.target.className.includes('tbe-cell')) {
      if (rowData.id !== this.state.selectedRowId) {
        this.setState({ selectedRowId: rowData.id })
        this.props.handleRowSelection(rowData, this.state.dataRaw)
      } else {
        this.setState({ selectedRowId: null })
        this.props.handleRowSelection(null, null)
      }
    }
  }

  // When the user clicks on a row checkbox (and table has item selection enabled)
  handleItemSelection = (e, { name, value: itemId, checked }) => {
    if (checked && !this.state.selectedItemsList[itemId]) {
      this.setState(
        prevState => ({
          selectedItemsList: {
            ...prevState.selectedItemsList,
            [itemId]: true,
          },
        }),
        () => {
          this.props.handleItemSelection(
            {
              itemId,
              checked,
            },
            this.state.selectedItemsList,
            this.state.dataRaw
          )
        }
      )
    } else {
      const {
        [itemId.toString()]: dummy,
        ...listRest
      } = this.state.selectedItemsList
      const {
        [this.state.activePage.toString()]: dummy2,
        ...allItemsPageRest
      } = this.state.allItemsPageSelected
      this.setState(
        { allItemsPageSelected: allItemsPageRest, selectedItemsList: listRest },
        () => {
          this.props.handleItemSelection(
            {
              itemId,
              checked,
            },
            this.state.selectedItemsList,
            this.state.dataRaw
          )
        }
      )
    }
  }

  // When the user clicks on a the header checkbox (and table has item selection enabled)
  handleAllItemsSelection = (e, { name, value, checked }) => {
    const currentPageItemsIds = this.props
      .dataExtraction(this.state.dataRaw)
      .reduce((t, v) => ({ ...t, [v.id]: true }), {})

    if (checked) {
      this.setState(
        prevState => ({
          allItemsPageSelected: {
            ...prevState.allItemsPageSelected,
            [prevState.activePage]: true,
          },
          selectedItemsList: {
            ...prevState.selectedItemsList,
            ...currentPageItemsIds,
          },
        }),
        () => {
          this.props.handleAllItemsSelection(
            { checked },
            this.state.selectedItemsList,
            this.state.dataRaw
          )
        }
      )
    } else {
      const {
        [this.state.activePage.toString()]: dummy,
        ...rest
      } = this.state.allItemsPageSelected
      const newSelectedItemsList = Object.keys(this.state.selectedItemsList)
        .filter(v => !currentPageItemsIds[v])
        .reduce((t, v) => ({ ...t, [v]: true }), {})
      this.setState(prevState => ({
        allItemsPageSelected: rest,
        selectedItemsList: newSelectedItemsList,
      }))
      this.props.handleAllItemsSelection(
        { checked },
        newSelectedItemsList,
        this.state.dataRaw
      )
    }
  }

  getData = () => {
    const {
      url,
      apiRequestOptions,
      hasPagination,
      pageUrlVar,
      pageSizeUrlVar,
      hasSort,
      sortColUrlVar,
      sortDirUrlVar,
      hasSearch,
      searchUrlVar,
      totalPagesExtraction,
      handleApiRequestError,
    } = this.props
    const {
      activePage,
      pageSize,
      sortColumn,
      sortDirection,
      search,
    } = this.state

    this.setState({ status: 'loading' })

    axios({
      method: 'get',
      url: `${url}${url.slice(-1) !== '&' ? '?' : ''}${
        hasPagination
          ? `${pageUrlVar}=${activePage}&${pageSizeUrlVar}=${pageSize}&`
          : ''
      }${
        hasSort
          ? `${sortColUrlVar}=${sortColumn}&${sortDirUrlVar}=${sortDirection}&`
          : ''
      }${
        hasSearch && search
          ? searchUrlVar.map(v => `${v}=${search}`).join('&')
          : ''
      }`,
      ...apiRequestOptions,
    })
      .then(response => {
        this.setState({
          dataRaw: response.data,
          totalPages: hasPagination ? totalPagesExtraction(response.data) : 1,
          status: 'ok',
        })
      })
      .catch(error => {
        console.error(error)
        this.setState({
          dataRaw: null,
          status: 'error',
          activePage: 1,
          totalPages: 1,
        })
        handleApiRequestError(error)
      })
  }

  render() {
    const {
      url,
      apiRequestOptions,
      header,
      dataExtraction,
      handleApiRequestError,
      showIdCol,
      hasLoader,
      hasPagination,
      pageUrlVar,
      pageSizeUrlVar,
      defaultPage,
      defaultPageSize,
      totalPagesExtraction,
      hasSearch,
      searchUrlVar,
      defaultSearch,
      hasSort,
      sortColUrlVar,
      sortDirUrlVar,
      defaultSortCol,
      defaultSortDir,
      hasRowSelection,
      handleRowSelection,
      hasItemsSelection,
      handleItemSelection,
      handleAllItemsSelection,
      ...rest
    } = this.props
    const {
      status,
      dataRaw,
      activePage,
      totalPages,
      search,
      sortColumn,
      sortDirection,
      selectedRowId,
      selectedItemsList,
      allItemsPageSelected,
    } = this.state

    const headerKeys = Object.keys(header)
    const nCols =
      headerKeys.length + (hasItemsSelection ? 1 : 0) - (showIdCol ? 0 : 1)
    const processedData = dataRaw ? dataExtraction(dataRaw) : []

    return (
      <MediaQuery minWidth={500}>
        {matches => (
          <Table sortable={hasSort} selectable={hasRowSelection} {...rest}>
            {hasSearch && (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={nCols}>
                    <DebounceInput
                      autoComplete="off"
                      id="tbe-search"
                      fluid
                      element={Input}
                      minLength={2}
                      debounceTimeout={300}
                      placeholder="Buscar..."
                      icon="search"
                      name="search"
                      value={search}
                      onChange={this.handleSearch}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}
            {header && headerKeys.length > 0 && (
              <Table.Header>
                <Table.Row>
                  <Fragment>
                    {hasItemsSelection && (
                      <Table.HeaderCell collapsing>
                        <Checkbox
                          checked={allItemsPageSelected[activePage]}
                          onChange={this.handleAllItemsSelection}
                        />
                      </Table.HeaderCell>
                    )}
                    {headerKeys.map(colId => {
                      if (!showIdCol && colId === 'id') return null

                      const { text, unsortable, ...hrest } = header[colId]
                      return (
                        <Table.HeaderCell
                          key={colId}
                          sorted={
                            hasSort && !unsortable && sortColumn === colId
                              ? `${sortDirection}ending`
                              : undefined
                          }
                          onClick={
                            hasSort && !unsortable
                              ? () => this.handleSort(colId)
                              : undefined
                          }
                          {...hrest}
                        >
                          {text}
                        </Table.HeaderCell>
                      )
                    })}
                  </Fragment>
                </Table.Row>
              </Table.Header>
            )}
            <Table.Body>
              {status === 'error' ? (
                <Table.Row>
                  <Table.Cell
                    colSpan={nCols}
                    textAlign="center"
                    style={{
                      padding: '2rem',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    <div>ERROR: No se pueden cargar los datos</div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                processedData.map(dataRow => (
                  <Table.Row
                    key={dataRow.id}
                    className="tbe-row"
                    style={hasRowSelection ? { cursor: 'pointer' } : undefined}
                    active={
                      hasRowSelection ? selectedRowId === dataRow.id : undefined
                    }
                    onClick={
                      hasRowSelection
                        ? e => {
                            this.handleRowSelection(e, dataRow)
                          }
                        : undefined
                    }
                  >
                    <Fragment>
                      {hasItemsSelection && (
                        <Table.Cell collapsing className="tbe-cell">
                          <Checkbox
                            checked={selectedItemsList[dataRow.id]}
                            value={dataRow.id}
                            onChange={this.handleItemSelection}
                          />
                        </Table.Cell>
                      )}
                      {Object.keys(dataRow).map(dataColId => {
                        if (!showIdCol && dataColId === 'id') return null

                        return (
                          <Table.Cell
                            key={dataRow.id + dataColId}
                            className="tbe-cell"
                          >
                            {dataRow[dataColId]}
                          </Table.Cell>
                        )
                      })}
                    </Fragment>
                  </Table.Row>
                ))
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={nCols}>
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: matches
                        ? 'row nowrap'
                        : 'column-reverse nowrap',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        height: '2.2rem',
                        marginTop: matches ? undefined : '1.2rem',
                      }}
                    >
                      {hasLoader && status === 'loading' && (
                        <div>
                          <Icon
                            loading
                            size="big"
                            name="spinner"
                            color="blue"
                          />
                          {'  '}
                          Cargando datos...
                        </div>
                      )}
                    </div>
                    {hasPagination && totalPages > 1 && (
                      <Pagination
                        floated="right"
                        activePage={activePage}
                        totalPages={totalPages}
                        onPageChange={this.handlePaginationChange}
                        {...(!matches
                          ? {
                              size: 'mini',
                              // ellipsisItem: null,
                              boundaryRange: 0,
                              siblingRange: 0,
                            }
                          : {})}
                      />
                    )}
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </MediaQuery>
    )
  }
}

export default TableBackEnd
